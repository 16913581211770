<template>
  <div class="chart" id="dealer-company-detail-chart">
  </div>

</template>

<script>
  import echarts from 'echarts'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    computed: {
      ...mapGetters(['theme'])
    },
    mounted: function () {
      let vue = this

      vue.$nextTick(function () {
        setTimeout(function () {
          vue._initCharts([])
          vue.changeTheme(vue.theme)
        }, 500)

      })

      this.$mq.Subcribe(`theme`, function (data) {
        vue.changeTheme(data)
      })

      this.$mq.Subcribe(`resize`, function (data) {
        setTimeout(function () {
          vue.chart.resize()
        }, 200)
      })
    },
    data: function () {
      return {
        chart: null,
        option: {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: {
                color: '#57617B'
              }
            }
          },
          legend: {
            selectedMode: false,
            type: 'plain',
            right: 300,
            data: ['一号设备', '二号设备', '三号设备'],

            textStyle: {
              color: '#333'
            }
          },
          grid: {
            left: '8%',
            bottom: '16%',
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              lineStyle: {
                color: '#E2E2E2'
              }
            },
            axisLabel: {
              color: '#333'
            },
            data: []
          },
          yAxis: {
            type: 'value',
            name: '制作杯量',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#E2E2E2'
              }
            },
            axisLabel: {
              color: '#131313'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#131313',
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            }
          },
          series: [{
            name: '一号设备',
            type: 'line',
            smooth: true,
            lineStyle: {
              normal: {
                width: 1
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0, 136, 212, 0.3)'
                }, {
                  offset: 0.8,
                  color: 'rgba(0, 136, 212, 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(0,136,212)'

              }
            },
            data: [96.3, 96.4, 97.5, 95.6, 98.1, 94.8, 89.6, 94.1, 80.1, 52.4, 75.8, 94.7]
          }, {
            name: '二号设备',
            type: 'line',
            smooth: true,
            lineStyle: {
              normal: {
                width: 1
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(137, 189, 27, 0.3)'
                }, {
                  offset: 0.8,
                  color: 'rgba(137, 189, 27, 0)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10

              }
            },
            itemStyle: {
              normal: {
                color: 'rgb(137,189,27)'
              }
            },
            data: [97.3, 99.2, 99.3, 100.0, 99.6, 90.6, 80.0, 91.5, 69.8, 67.5, 90.4, 84.9,
              97.3, 99.2, 99.3, 100.0, 99.6, 90.6, 80.0, 91.5, 69.8, 67.5, 90.4, 84.9]
          }]
        },
      }
    },
    methods: {
      changeTheme(val) {
        let vue = this
        if (val === 'dark') {
          vue.option.legend.textStyle.color = '#AAAFBA'
          vue.option.yAxis.splitLine.lineStyle.color = '#676f77'
          vue.option.yAxis.axisLine.lineStyle.color = '#AAAFBA'
          vue.option.xAxis.axisLabel.color = '#AAAFBA'
          vue.option.yAxis.axisLabel.color = '#AAAFBA'
        } else {
          vue.option.legend.textStyle.color = '#131313'
          vue.option.yAxis.splitLine.lineStyle.color = '#e1e1e1'
          vue.option.yAxis.axisLine.lineStyle.color = '#131313'
          vue.option.xAxis.axisLabel.color = '#131313'
          vue.option.yAxis.axisLabel.color = '#131313'
        }
        this.chart.setOption(this.option)
      },
      _initCharts() {
        let vue = this
        vue.chart = echarts.init(document.getElementById('dealer-company-detail-chart'))
        let date = new Date()
        for (let i = 1; i <= date.getDate(); i++) {
          this.option.xAxis.data.push(i)
        }
      }
    },
  }
</script>

<style lang="scss">
  .chart {
    width: 100%;
    height: 100%;
  }
</style>
